@mixin set-str-width($strs) {
  &-#{$strs}-strs .string{
    width: calc(100% / $strs)
  }
}

$c_red: #ED2839;
$c_orange: #FF9447; //#F56600;
$c_yellow: #FEDF72;
$c_green: #9BCB7C;
$c_blue: #B2E2FB; //#87D3F8;
$c_indigo: #C9ADFF; //#330099;
$c_violet: #ECADFF; //#BC00F5;
$c_light-gray: #d6d6d6; //#ebebeb

$box-shadow: 0 0 4px 2px;

$num-of-strs: (
  4, 5, 6, 7, 8, 9, 10, 11, 12
);
.has {
  @each $num in $num-of-strs {
    @include set-str-width($num)
  }
}

.App {
  padding: 0 0.5rem 2rem;
  @media (min-width: 480px) {
    padding: 0 1rem 2rem;
  }
  @media (min-width: 720px) {
    padding: 0 2rem 2rem;
  }
}

h3 {
  text-align: center;
}

.options {
  font-size: 0.875rem;
  @media (min-width: 480px) {
    font-size: 1rem;
  }
  .row {
    display: flex;
    justify-content: space-evenly;
    .sharps-or-flats {
      width: 25%;
      @media (min-width: 720px) {
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
      label {
        display: flex;
        align-items: center;
        height: 1.5rem;
        padding: 0.25rem;
        &:first-child {
          margin-bottom: 0.25rem;
        }
        input {
          margin: 0;
          margin-right: 0.5rem;
          @media (min-width: 480px) {
            width: 1.25rem;
            height: 1.25rem;
          }
          // &:checked + span {}
        }
      }
    }
    .num-of-strings {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 0 0.5rem;
      margin: 0 0.25rem;
      border: solid 2px black;
      border-top: none;
      border-bottom: none;
      @media (min-width: 720px) {
        padding: 0 5%;
        width: 25%;
        max-width: 15rem;
      }
      .str-options {
        button {
          width: 33%;
        }
      }
      .increment {
        button {
          width: 50%;
        }
      }
      button {
        height: 1.75rem;
      }
    }
  }
  .all-strs-step {
    width: 25%;
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    justify-content: center;
    span {
      display: block;
      margin-bottom: 0.5rem;
      width: 100%;
    }
    button {
      width: 40%;
      height: 2.5rem;
    }
  }
}

.chord-sel {
  &, .chord, .freeze-ctrls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
  }
  & { // for column-gap to be applied
    column-gap: 1rem;
    padding: 0.5rem 1rem;
    margin-top: 0.5rem;
    border: 2px solid black;
    border-left: none;
    border-right: none;
  }
  .chord {
    @media (min-width: 720px) {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }
    label {
      grid-column: 1 / 3;
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0;
      // @media (min-width: 720px) {
      //   padding-right: 1rem;
      // }
      text-align: center;
      }
    select {
      height: 2rem;
      @media (min-width: 720px) {
        width: 4rem;
        text-align: center;
      }
    }
    .chordTonic {
      grid-column: 1 / 3;
    }
    .quality {
      grid-column: 1 / 2;
    }
    .ext {
      grid-column: 2 / 3;
    }
  }
  .freeze-ctrls {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0.5rem;
    padding-left: 1rem;
    padding-right: 0;
    border-left: 2px solid black;
    @media (min-width: 720px) {
      display: flex;
      justify-content: space-evenly;
      button {
        height: 2rem;
        width: 50%;
        max-width: 10rem;
      }
    }
  }
}

.neck {
  margin-top: 1rem;
  display: flex;
  @media (min-width: 480px) {
    display: block;
  }
  .fret-nums {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* height: 1.5rem; */
    padding-bottom: 0.5rem;
    border-bottom: solid 2px black;
    width: 2rem;
    @media (min-width: 480px) {
      width: auto;
      height: 2em;
      display: grid;
      grid-template-columns: 0.5fr repeat(12, 1fr);
    }
    .open {
      font-size: 0.8rem;
      padding: 0 0.25rem;
      height: 3rem;
      @media (min-width: 480px) {
        height: auto;
      }
    }
    .fret {
      // margin-left: 0;
      border-left: 0;
    }
  }
  .all-strings {
    display: flex;
    flex-direction: row-reverse;
    width: calc(100vw - 3rem);
    @media (min-width: 480px) {
      flex-direction: column;
      width: auto;
    }
  }
  .string {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0.5rem 0;
    border-bottom: solid 2px black;
    @media (min-width: 480px) {
      padding: 0.25rem 0;
      width: auto;
      display: grid;
      grid-template-columns: 0.5fr repeat(12, 1fr);
    }
    .str-select {
      border: none;
      width: calc(100% - 0.5rem);
      select {
        height: 2rem;
      }
    }
  }

  .fret {
    border-left: solid 2px black;
    border-bottom: solid 2px black;
    padding: 0 0.25rem;
    margin: 0.25rem;
    height: 2.5em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (min-width: 480px) {
      border-bottom: none;
      padding: 0;
      margin: 0;
    }
  }
  .selected {
    font-weight: 700;
    box-shadow: $box-shadow currentColor;
    &.root {
      background-color: $c_red;
      box-shadow: $box-shadow $c_red;
      z-index: 1;
    }
    &.third {
      background-color: $c_orange;
      box-shadow: $box-shadow $c_orange;
      z-index: 1;
    }
    &.fifth {
      background-color: $c_yellow;
      box-shadow: $box-shadow $c_yellow;
    }
    &.sixth {
      background-color: $c_green;
      box-shadow: $box-shadow $c_green;
    }
    &.seventh {
      background-color: $c_blue;
      box-shadow: $box-shadow $c_blue;
    }
    &.ninth {
      background-color: $c_indigo;
      box-shadow: $box-shadow $c_indigo;
    }
    &.eleventh {
      background-color: $c_violet;
      box-shadow: $box-shadow $c_violet;
    }
  }
}

.frozen-chords {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 1rem;
  @media (min-width: 720px) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
  }
  .frz-chord {
    font-size: 0.75rem;
    padding: 0 0.5rem;
    h4 {
      font-size: 0.875rem;
      text-align: center;
      margin: 1rem 0 0.5rem;
    }
    .neck {
      margin: 0;
      display: block;
      .all-strings {
        flex-direction: column;
        width: auto;
      }
      .fret-nums, .string {
        width: auto;
        height: 2.5em;
        height: auto;
        display: grid;
        grid-template-columns: 2.25em repeat(4, 1fr);
        @media (min-width: 480px) {
          gap: 2px;
        }
      }
      .fret-nums {
        border-bottom: none;
        border-top: solid 2px black;
        background-color: $c_light-gray;
        padding-bottom: 0;
        opacity: 0.75;
        .open {
          font-size: .75em;
          height: auto;
          padding: 0;
          background-color: $c_light-gray;
        }
      }
      .fret {
        border: none;
        border-right: solid 2px black;
        padding: 0;
        margin: 0;
      }
      .string {
        border: none;
        padding: 0.25em 0;
        .fret {
          border-top: solid 2px black;
          &:first-child {
            border-top-color: transparent;
            opacity: 0.8;
            &:not(.selected) {
              background-color: $c_light-gray;
            }
          }
          &:last-child {
            border-right: none;
          }
        }
        &:first-child {
          .fret {
            border-top: none;
          }
        }
      }
    }
  }
}

.contact {
  font-size: 0.75rem;
  text-align: center;
  padding-top: 4rem;
}